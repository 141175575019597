
import { Component, Watch } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Getter, Mutation } from "vuex-class";
import { IPagination } from "@/types";
import {
  ICustomerPriceTag,
  ICustomerPriceTagListRequest
} from "@/types/customer_price_tag";

@Component({})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  //customer_price_tag
  @Action("customer_price_tag/adminGetList")
  public getList!: (request: ICustomerPriceTagListRequest) => boolean;

  @Getter("customer_price_tag/list")
  public customer_product_list!: ICustomerPriceTag[];

  @Getter("customer_price_tag/pagination")
  public pagination!: IPagination<ICustomerPriceTag[]>;

  @Mutation("customer_product/clear")
  public clear!: () => void;

  // watch
  @Watch("$route.query")
  public async changeQuery() {
    this.queryToParams();
    await this.getList(this.requestParams);
  }

  //
  // variables
  //
  public headers = [
    { text: "顧客品番", value: "customer_pn", sortable: false },
    { text: "顧客名", value: "customer_name", sortable: false }
  ];

  // パラメータ定義
  public requestParams: ICustomerPriceTagListRequest = {
    page: 1,
    price_tag_id: 0
  };

  //
  // methods
  //
  private async created() {
    this.clear();
    this.queryToParams();
    await this.getList(this.requestParams);
  }

  // urlからパラメータをセットする
  private queryToParams() {
    const routeQuery = this.$route.query;
    const routeParams = this.$route.params;

    this.requestParams = {
      page: routeQuery.page ? Number(routeQuery.page) : 1,
      price_tag_id: routeParams.price_tag_id
        ? Number(routeParams.price_tag_id)
        : 0
    };
  }

  // 1ページ目から検索
  // 主に検索条件が変更されたときに利用
  public async searchFirst() {
    this.requestParams.page = 1;
    await this.paramsToQuery();
  }

  // URLのパラメータをセットする
  private paramsToQuery() {
    this.$router
      .push({
        path: this.$router.currentRoute.path,
        query: {
          page: this.requestParams.page ? String(this.requestParams.page) : "1"
        }
      })
      .catch(() => {});
  }

  // 行をクリックした際の挙動
  public rowClick(customer_price_tag: ICustomerPriceTag) {
    this.$router.push(
      `/admin/customer_price_tag/${customer_price_tag.id}/edit`
    );
  }
}
